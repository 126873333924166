import axios from "axios";

let API_URL = "";
if (process.env.NODE_ENV !== "production") {
  API_URL = "http://localhost:5000/api/surveys/"; // lets us use the backend server in local development
} else {
  API_URL = "/api/surveys/";
}

const getApplications = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const { data } = await axios.get(API_URL + "applications", config);
    return data;
}

const getPreworkshop = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const { data } = await axios.get(API_URL + "preworkshop", config);
    return data;
}

const getPostworkshop = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const { data } = await axios.get(API_URL + "postworkshop", config);
    return data;
}

const getFollowup = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const { data } = await axios.get(API_URL + "followup", config);
    return data;
}

const getConsent = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const { data } = await axios.get(API_URL + "consent", config);
    return data;
}

const surveyService = { getApplications, getPreworkshop, getPostworkshop, getFollowup, getConsent };

export default surveyService;