import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import surveyService from "./surveyService";

const initialState = {
    applications: [],
    consent: [],
    preworkshop : [],
    postworkshop : [],
    followup : [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
};

// GET All Applications
export const getApplications = createAsyncThunk(
    "surveys/getApplications",
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token;
            return await surveyService.getApplications(token);
        } catch (error) {
            const message = error.response.data.message || error.message;
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// GET Preworkshop
export const getPreworkshop = createAsyncThunk(
    "surveys/getPreworkshop",
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token;
            return await surveyService.getPreworkshop(token);
        } catch (error) {
            const message = error.response.data.message || error.message;
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// GET Postworkshop
export const getPostworkshop = createAsyncThunk(
    "surveys/getPostworkshop",
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token;
            return await surveyService.getPostworkshop(token);
        } catch (error) {
            const message = error.response.data.message || error.message;
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// GET Followup
export const getFollowup = createAsyncThunk(
    "surveys/getFollowup",
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token;
            return await surveyService.getFollowup(token);
        } catch (error) {
            const message = error.response.data.message || error.message;
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const getConsent = createAsyncThunk(
    "surveys/getConsent",
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token;
            return await surveyService.getConsent(token);
        } catch (error) {
            const message = error.response.data.message || error.message;
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const surveySlice = createSlice({
    name: "surveys",
    initialState,
    reducers: {
        reset : (state) => initialState,
    },
    extraReducers: (builder) => {
        
        builder
            .addCase(getApplications.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getApplications.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.applications = payload;
            })
            .addCase(getApplications.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.isError = true;
                state.message = payload;
            })
            .addCase(getPreworkshop.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getPreworkshop.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.preworkshop = payload;
            })
            .addCase(getPreworkshop.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.isError = true;
                state.message = payload;
            })
            .addCase(getPostworkshop.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getPostworkshop.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.postworkshop = payload;
            })
            .addCase(getPostworkshop.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.isError = true;
                state.message = payload;
            })
            .addCase(getFollowup.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getFollowup.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.followup = payload;
            })
            .addCase(getFollowup.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.isError = true;
                state.message = payload;
            })
            .addCase(getConsent.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getConsent.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.consent = payload;
            })
            .addCase(getConsent.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.isError = true;
                state.message = payload;
            });
    }
});

export const { reset } = surveySlice.actions;

export default surveySlice.reducer;